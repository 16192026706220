@import 'theme';
form input,
form select {
    border: 1px solid #e8e8e8;
    width: 80%;
    display: block;
    align-content: center;
    /* flex-grow: 1; */
    padding: 0.6rem;
    margin: 1rem auto;
}

body {
    height: 100%;
    margin: 0;
    overscroll-behavior: contain;
    background-color: #EDEDED;
    touch-action: manipulation;
}

h1 {
    font-size: 150%;
}

h2 {
    font-size: 130%;
}

h3 {
    font-size: 120%;
}

h4 {
    font-size: 110%;
}

li {
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
}

// Hide Landbot bubble
.LandbotLauncher {
    display: none;
}

// Fill the screen with the Landbot livechat window
.LandbotLivechat .LandbotFrame[data-open='true'] {
    bottom: 0 !important;
    height: 100% !important;
    width: 100% !important;
}

.highlight {
    color: $primary-button;
    font-weight: 600;
}

.card-header {
    font-size: 1.3rem;
}

.card-body {
    padding: 1rem;
}

// Setting medium icon styling
.icon-medium {
    width: 34px;
}

// Setting smaller (medium) icon styling
.icon-smaller {
    width: 29px;
    position: relative;
    top: -2px;
    &.shift-right {
        margin-left: -6px;
    }
}

// Setting smallest icon styling
.icon-small {
    width: 20px;
}

// Setting card section styling
.card-section {
    margin: 15px 0;
}

// Orange card section
.card-section-warning {
    margin: 15px 0;
    background: rgba(red($f-orange), green($f-orange), blue($f-orange), 0.16);
    .card-section-warning-title {
        color: $g-orange;
        padding-bottom: 0;
    }
    a {
        color: $f-orange;
        &:hover {
            color: darken($f-orange, 10%);
        }
    }
}

// Red card section
.card-section-danger {
    margin: 15px 0;
    background: rgba(red($f-red), green($f-red), blue($f-red), 0.16);
    .card-section-danger-title {
        color: $f-red;
        padding-bottom: 0;
    }
    a {
        color: $f-red;
        &:hover {
            color: darken($f-red, 10%);
        }
    }
}

// Red card section
.card-section-info {
    margin: 15px 0;
    background: rgba(red($f-teal), green($f-teal), blue($f-teal), 0.16);
    .card-section-info-title {
        color: $f-teal;
        padding-bottom: 0;
    }
    a {
        color: $f-teal;
        &:hover {
            color: darken($f-teal, 10%);
        }
    }
}

// Setting icon heading (large icon with text on the right of the heading)
.icon-heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $f-black;
    .icon-heading-info {
        margin-left: 10px;
    }
    .icon-heading-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 0.2rem;
    }
    .icon-heading-subtitle {
        font-size: 18px;
    }
}

// Setting icon title (small icon with text on the right of the heading)
.icon-title {
    font-size: 1.07rem;
    font-weight: 600;
    margin-bottom: 5px;
    .icon-title-icon {
        margin-right: 10px;
    }
}

// Setting icon heading (icon with text on the bottom of the heading)
.icon-top-heading {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    img {
        width: 40px;
    }
}

// Global styling for spinning loader
svg.loader-spinner {
    transform-origin: center;
    animation: rotate 2s linear infinite;
    display: block;
    z-index: 3;
    position: relative;
}

svg.loader-spinner circle {
    fill: none;
    stroke: $f-orange;
    stroke-width: 3;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dashoffset: -125px;
    }
}

// Global styling for stepper component
.step-header {
    margin-left: 20px;
    font-size: 1.33rem;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .counter {
        font-size: 0.93rem;
        font-weight: 600;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        background: $f-orange;
        color: #fff;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        // Added overrides for wider steps (containing more info)
        &-wide {
            border-radius: 28px;
            width: inherit;
            padding: 0 10px;
        }
    }
    &.profile-completeness {
        background: #707070;
        margin: 0;
        .counter {
            margin: 0;
            white-space: nowrap;
        }
        .container {
            display: grid;
            grid-template-columns: min-content 1fr;
            align-items: center;
            padding-top: 1rem;
            padding-bottom: 1rem;
            @media (min-width: 767px) {
                grid-template-columns: min-content 1fr 1fr;
            }
            h2 {
                margin: 0;
                color: #fff;
                font-size: 19px;
                text-align: right;
            }
        }
    }
}

.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        margin-right: 6px;
    }
}

// Overriding the ReactModal component styling
.ReactModal__Overlay {
    background-color: rgba(red(#000), green(#000), blue(#000), 0.85) !important;
    // z-index: 1002 !important;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    padding: 20px;
    overflow-y: auto;
}

.ReactModal__Content {
    background: #fff !important;
    color: $f-black !important;
    border: transparent !important;
    box-shadow: $global-shadow;
    border-radius: 10px;
    &:focus {
        outline: none;
    }
}

.help-section {
    font-size: 14px;
    h3 {
        margin-bottom: 0.8rem;
        font-size: 1.125rem;
    }
    a {
        font-size: 1.125rem;
        margin-bottom: 0.8rem;
        display: inline-block;
        line-height: 1.2;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

#root {
    position: relative;
    z-index: 0;
}

.desktop-background-image {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: none;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @media (min-width: 768px) {
        background-image: url('../public/assets/images/my-reservations-hero-background.jpg');
    }
    &:after {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(135deg, rgba(109, 65, 0, 1) 0%, rgba(11, 12, 16, 1) 100%);
        opacity: 0.65;
        z-index: 0;
        @media (min-width: 768px) {
            display: block;
        }
    }
    &.login-background {
        background-image: url(../public/assets/images/my-reservations-hero-background.jpg) !important;
        &:after {
            display: block !important;
        }
    }
    &.profile-completeness-background {
        background-image: none;
        @media (min-width: 768px) {
            background-image: url(../public/assets/images/profile-completeness-hero-background.jpg) !important;
        }
        &:after {
            //display: block !important;
        }
    }
}

.info-section {
    padding-left: 1rem;
    padding-right: 1rem;
    &.info-section-white {
        background: #fff;
    }
}

.version-number-container {
    position: fixed;
    bottom: 0;
    right: 15px;
    margin-right: auto;
}

.root-ver-text {
    float: right;
    font-size: 0.7em;
    color: whitesmoke;
}

.step-buttons {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 1rem;
    .btn {
        font-size: 18px;
        padding: 0.75rem 0;
        @media (min-height: $i-phone-se-height-viewport) and (min-height: $iphone-se-toolbar-collapsed-viewport-height) {
            padding: 0.75rem 1rem;
        }
    }
}

.help-section {
    width: 86%;
}

.steps {
    background: #fff;
    box-shadow: 0 6px 6px #00000015;
    position: sticky;
    top: 0;
    z-index: 3;
}

.step-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: -4px;
    left: -5px;
    margin-bottom: 5px;
    h1 {
        margin: 0;
        font-size: 22px;
        font-weight: 300;
        float: right
    }
}

.step-subtitle {
    margin: 0;
    font-size: 15px !important;
    font-weight: 700;
}

.step-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.small-icon-button.btn {
    img {
        width: 18px;
        position: relative;
        top: -1px;
    }
}

.inspect-sheet-placeholder {
    border: 1px solid #b7b7b7;
    border-radius: 5px;
}

.leaflet-control-container {
    position: relative;
    z-index: 1000;
    display: block;
    height: 120px;
}

.modal-toggle {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #fff;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 5px 7px #00000020;
    z-index: 0;
    &.modal-toggle--inspect {
        z-index: 2;
    }
    img {
        width: 21px;
    }
}

.modal-bottom {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -90px;
    width: 180px;
    color: #fff;
    background-color: #0bb395;
    border-color: #0bb395;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 5px 7px #00000020;
    z-index: 0;
}

.map {
    position: relative;
    >img {
        object-fit: none;
        object-position: 50% 35%;
    }
}

.bg-light-gray {
    background: $f-light-gray;
}

.tooltip-link a {
    color: #fff !important;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Body--closed {
    overflow: auto;
}

.tippy-box {
    background-color: rgba(36, 36, 36, 0.9) !important;
}

@keyframes orientationRotate {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(-90deg);
    }
    100% {
        transform: rotate(-90deg);
    }
}

.orientation-warning-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1.5rem;
    height: 100%;
    >div {
        display: grid;
        align-items: center;
        justify-content: center;
    }
    img {
        width: 190px;
        margin-bottom: 1rem;
    }
    svg {
        animation: orientationRotate 1.5s ease-in-out infinite alternate;
        align-self: center;
        justify-self: center;
        width: 70px !important;
        height: 70px !important;
        display: block;
    }
    span {
        font-size: 16px;
        margin-top: 1rem;
    }
}

//body {
//  background: teal;
//
//  @media(min-height: $i-phone-se-height-viewport) {
//    background: purple;
//  }
//
//  @media(min-height: $i-phone-8-7-plus-height-viewport) {
//    background: orange;
//  }
//}
.loader-spinner {
    width: 50px;
    margin: 0 auto;
    circle {
        width: 51px;
        margin: 0 auto;
        stroke-width: 4;
    }
}

.main-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.disclaimer {
    font-size: 0.85rem;
    text-align: center;
    margin-top: 0.5rem;
}

.maintenance {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    background-color: $f-orange;
    height: 100vh;
    text-align: center;
    margin: auto 0;
    div {
        padding: 20px;
        background-color: $f-dark-gray;
    }
    h2 {
        padding: 20px;
    }
}

.btn-all {
    border-radius: 30px;
}