// Colors
//$f-orange: #FBB040;
$f-orange: #fdb022;
$f-gray: #989898;
$f-dark-gray: #707070;
$f-teal: #0BB395;
$g-orange: #FBB040;
$f-red: #F53232;
$f-light-gray: #F5F5F5;
$f-white: #fff;
//$f-black: #343a40;
$f-black: #333333;
$primary-button: $f-orange;
$secondary-button: #989898;
$global-shadow: 0 3px 6px rgba(0, 0, 0, .12);
// $secondary-button: #dfc12a;
$disabled-button-outline: purple;
//$marker-border: green;
$marker-border: #0bb395;
$logo-bg: #444;
//$primary-font-link: "https://fonts.googleapis.com/css?family=Raleway";
//$primary-font-name: "Raleway";
$primary-font-link: "https://fonts.googleapis.com/css?family=Open+Sans";
$primary-font-name: "Open Sans";
// iPhone Sizes
$i-phone-address-button-bars-height: 113px;
$iphone-se-toolbar-collapsed-viewport-height: 529px;
$i-phone-se-height-screen: 568px;
$i-phone-se-height-viewport: $i-phone-se-height-screen - $i-phone-address-button-bars-height;
$i-phone-6-7-8-s-plus-height-screen: 667px;
$i-phone-6-7-8-s-plus-height-viewport: $i-phone-6-7-8-s-plus-height-screen - $i-phone-address-button-bars-height;
$i-phone-8-7-plus-height-screen: 736px;
$i-phone-8-7-plus-height-viewport: $i-phone-8-7-plus-height-screen - $i-phone-address-button-bars-height;
$i-phone-x-height-screen: 812px;
$i-phone-x-height-viewport: $i-phone-x-height-screen - $i-phone-address-button-bars-height;